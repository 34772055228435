<template>
  <div>
    <span
      class="text-pre-wrap"
      style="word-break: break-word !important;"
    >{{ getShortText(text) }}<span v-if="readMore"></span><span v-else-if="(getLongText(text).length > 0)">...</span><span v-show="readMore">{{ getLongText(text) }}</span>
    </span>

    <v-btn
      v-if="getLongText(text)"
      @click="clickMethod"
      text
      small
      class="ma-0 px-0"
    >
      <span v-if="!readMore">Zobraziť viac</span>
      <span v-else>Zobraziť menej</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "ReadMoreText",
  props: {
    length: { type: Number, required: true },
    text: { type: String, required: true },
    clickCallBack: { type: Function, required: false, default: null },
    commentId: { type: Number, required: false, default: null }
  },
  data: () => ({
    readMore: false,
  }),

  methods: {
    clickMethod() {
      if (!this.readMore) {
        if (this.commentId) {
          this.clickCallBack(this.commentId);
        }
      }

      this.readMore = !this.readMore;
    },
    getShortText(text) {
      return text.slice(0, this.length);
    },

    getLongText(text) {
      return text.slice(this.length, text.length);
    },
  },
};
</script>