<template>
  <div>
    <v-alert
      color="blue-grey"
      dark
      outlined
      dense
      class="text-center elevation-15"
    > Začiatok volieb: <strong>{{ getSettings.volby_zaciatok }}</strong>. Koniec volieb: <strong>{{ getSettings.volby_koniec }}</strong>.
      <span v-if="user">
        Tvoj celkový počet hlasov:
        <strong>{{ user.votes_given + user.votes_available }}</strong>. Použil si: <strong>{{ user.votes_given }}</strong>. Zostáva Ti: <strong>{{ user.votes_available }}</strong>.</span>
    </v-alert>

    <v-row>
      <v-col
        class="d-flex"
        v-for="item in itemsSorted"
        :key="item.id"
        cols="12"
        md="4"
        sm="12"
        align-self="start"
      >
        <v-card
          :class="getCardColor(item)"
          class="elevation-15"
        >

          <v-img
            contain
            max-height="250"
            :src="item.photo"
          >
            <v-row>
              <v-col>
                <v-card-actions>
                  <v-btn
                    v-if="item.user_voted"
                    @click="unvote(item)"
                    color="red lighten-2"
                    dark
                  >
                    Odobrať hlas
                  </v-btn>

                  <v-btn
                    v-if="item.can_user_vote && !item.user_voted"
                    @click="vote(item)"
                    color="green lighten-2"
                    dark
                  >
                    Dať hlas
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="item.user_favorite"
                        color="amber amber-darken-4"
                        v-bind="attrs"
                        v-on="on"
                        @click="removeFavorite(item)"
                      >
                        mdi-star
                      </v-icon>
                      <v-icon
                        v-else
                        color="amber amber-darken-4"
                        v-bind="attrs"
                        v-on="on"
                        @click="addFavorite(item)"
                      >
                        mdi-star-outline
                      </v-icon>
                    </template>
                    <span>Favorit</span>
                  </v-tooltip>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-img>

          <v-card-title v-if="item.candidate.user">{{ item.candidate.user.first_name }}
            {{ item.candidate.user.last_name }} <v-spacer></v-spacer> Poradie: {{ item.rank }}.</v-card-title>
          <v-card-subtitle class="text-center">Počet hlasov: {{item.votes}}</v-card-subtitle>

          <v-card-subtitle>
            {{ item.candidate.user.address_town }}
          </v-card-subtitle>
          <v-divider class="mx-3"></v-divider>

          <v-card-text>
            <read-more-text
              :length="100"
              :text="item.short_info"
            ></read-more-text>
          </v-card-text>

          <v-divider class="mx-3"></v-divider>
          <v-card-text
            class="text-center"
            v-if="item.video"
          >
            <v-btn
              text
              color="indigo darken-1"
              @click="openVideo(item)"
            >
              Videosľub kandidáta
            </v-btn>
          </v-card-text>

          <v-divider class="mx-3"></v-divider>

          <v-card-text>
            <read-more-text
              :length="500"
              :text="item.long_info"
            ></read-more-text>
          </v-card-text>

        </v-card>

      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      :width="videoWidth + 60"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Videosľub

          </v-toolbar-title>

        </v-toolbar>
        <v-card-text class="text-center">
          <video
            :key="dialog_video"
            :width="videoWidth"
            controls
            class="ma-3"
          >
            <source
              :src="dialog_video"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </v-card-text>

        <v-divider></v-divider>

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Zavrieť
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { APIService } from "@/http/APIService.js";
import ReadMoreText from "@/components/ReadMoreText.vue";
import { mapGetters } from "vuex";

const apiService = new APIService();

export default {
  data: () => ({
    loading: false,
    item: undefined,
    itemsSorted: [],
    items: [],
    user: null,

    dialog: false,
    dialog_video: null
  }),

  components: {
    ReadMoreText,
  },

  computed: {
    videoWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 250
        case 'sm': return 350
        case 'md': return 450
        case 'lg': return 550
        case 'xl': return 650
        default: return 400
      }
    },
    ...mapGetters("settings", ["getSettings"]),
  },

  mounted: function () {
    this.refreshItems();
  },

  methods: {
    openVideo(item) {
      this.dialog_video = item.video;
      this.dialog = true;
    },

    getCardColor(item) {
      if (item.user_voted) {
        return "mx-auto my-12 flex d-flex flex-column success lighten-5";
      } else {
        return "mx-auto my-12 flex d-flex flex-column";
      }
    },

    addFavorite(item) {
      this.loading = true;
      apiService.addFavoriteKandidat(item.id).then((response) => {
        this.item = response;
        this.loading = false;
        this.refreshItems();
      });
    },

    removeFavorite(item) {
      this.loading = true;
      apiService.removeFavoriteKandidat(item.id).then((response) => {
        this.item = response;
        this.loading = false;
        this.refreshItems();
      });
    },

    getItem(id) {
      this.loading = true;
      apiService.getKandidat(id).then((response) => {
        this.item = response;
        this.loading = false;
      });
    },

    vote(item) {
      apiService.voteKandidat(item.id).then((response) => {
        this.item = response.item;
        this.refreshItems();
      });
    },

    unvote(item) {
      apiService.unvoteKandidat(item.id).then((response) => {
        this.item = response.item;
        this.refreshItems();
      });
    },

    refreshItems() {
      this.getItems();
      this.getMe();
    },

    getItems() {
      this.loading = true;
      apiService.getKandidats("", "", 100, 0).then((response) => {
        this.items = response;

        this.itemsSorted = response.results;

        this.loading = false;
      });
    },

    getMe() {
      apiService.getMe().then((response) => {
        this.user = response;
      });
    },
  },
};
</script>